<template>

 


  <div :class=" type == 1 ? 'bac_img2' : 'bac_img3'" v-loading="loading">
    <div style="transform: translate(-30px, 20px);color:#ffff;font-size:16px;cursor:pointer;    position: absolute;
    right: 0;" @click="gohome">
      
      <i class="el-icon-back" ></i>返回首页
  </div>
    <div class="loginbigbox">
      <div class="loginbox">
        <img src="../../assets/image/Header/logo.png" alt="" class="logo_pic" />
        <div class="mar_10">
          <div class="login_title">青海省中小企业公共服务平台</div>
          <div class="login_Province">
            Public service platform for SME of Qinghai Province
          </div>
        </div>
      </div>
      <div class="contentbox">
        <!-- <img src="../../assets/image/login_img.png" class="signimg" alt="" /> -->
        <img src="../../assets/image/login_img2.png" alt="" v-if="type == 1" class="signimg" />
        <img src="../../assets/image/login_img3.png" alt="" v-else-if="type == 2" class="signimg3" />
        <div>
          <div class="contenttitle" v-if="type == 1">
            您好！<br>欢迎登录服务机构端
          </div>
          <div class="contenttitle" v-else-if="type == 2">
            您好！<br>欢迎登录服务专员端
          </div>

          <div class="sort"></div>
          <div class="phonebox">
            <div class="title_phone">手机号</div>
            <el-input v-model="phone" placeholder="请输入手机号"></el-input>
          </div>
          <div class="title_phone">验证码</div>

          <div class="dis" style="display: flex">
            <el-input
              v-model="verification"
              placeholder="请输入验证码"
            ></el-input>
            <!-- <div class="verification">获取手机验证码</div> -->
            <span v-show="show" @click="getCode" class="verification"
              >获取验证码</span
            >
            <span v-show="!show" class="verification"
              >{{ count }}s后重新获取</span
            >
          </div>

          <div class="read">
            <el-checkbox v-model="checked"></el-checkbox>
            <span
              >我已阅读并同意
              <a href="https://www.smeqh.cn/static/agreement.pdf" class="user" target="_blank">《青海省中小企业统一身份认证平台用户协议》</a>
   
              <!-- <div class="user">《青海省中小企业统一身份认证平台用户协议》</div> -->
              <!-- <div class="useragreement">(用户协议)</div>
              和
              <div class="privacypolicy">(隐私政策)</div> -->
              </span>

          </div>

          <div :class=" type == 1 ? 'signbox' :'signbox2'" ><div @click="gologin()">登录</div></div>
        </div>
      </div>
    </div>

    <div style="
    text-align: center;
    color: #ffff;
    transform: translate(0px, 90px);">
      温警提示: 出于安全考虑，一且您访问过那些需要您提供凭证信息的应用时，请操作完之后关闭浏览器
Copyright(C)www.smegh.cn 青海经信中小企业服务有限公司
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      verification: "",
      checked: false,
      show: true,
      count: "",
      timer: null,
      type: "",
      loading: false,
    };
  },
  mounted() {
    this.type = this.$route.query.num;
    console.log(this.type, "this.type");
    // this.$route.query
  },
  methods: {
    gohome(){
      this.$router.push({
              path: "/index",
            });
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        console.log("456");
        this.gocodes();
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    gocodes() {
      let data = {
        mobile: this.phone,
      };
      this.$get("/get_code", data).then((res) => {
        console.log(res.result, "res");
      });
    },
    gologin() {
      // this.loading = true;

      if (this.checked != true) {
        this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: "请阅读用户协议和隐私政策",
        });
      } else if (this.verification === "") {
        this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: "请输入验证码",
        });
      } else if (this.phone === "") {
        this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: "请输入验证码",
        });
      } else {
        let data = {
          mobile: this.phone,
          code: this.verification,
          type: this.type,
        };
        this.$post("/login", data).then((res) => {
          console.log(res.result, "res");
          if (res.status === 1) {
            this.loading = false;
            localStorage.setItem("eleToken", res.result);
            localStorage.setItem("phone", this.phone);
            this.$router.push({
              path: "/index",
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bac_img {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/login.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bac_img2 {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/login2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bac_img3 {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/image/login3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

  .loginbigbox {
    width: 1500px;
    height: 778px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: -7.36px 3.13px 13px 0px rgba(255, 237, 207, 0.46);
    position: relative;
    top: 80px;
    margin: 0 auto;

    .loginbox {
      display: flex;
      position: relative;
      top: 50px;
      left: 70px;
      z-index: 2;
      .logo_pic {
        width: 70px;
        height: 70px;
        background-size: 100% 100%;
      }
      .login_title {
        width: 460px;
        height: 31px;
        font-size: 32px;
        font-family: Source Han Sans SC Bold, Source Han Sans SC Bold-Bold;
        font-weight: 700;
        text-align: left;
        color: #ff712a;
        line-height: 31px;
      }
      .login_Province {
        width: 460px;
        height: 30px;
        font-size: 16px;
        font-family: Source Han Sans SC Regular,
          Source Han Sans SC Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #ff712a;
        line-height: 30px;
        letter-spacing: 0.65px;
      }
    }

    .contentbox {
      width: 1500px;
      // margin: 0 auto;
      position: relative;
      top: 100px;
      display: flex;
      justify-content: space-around;
      .signimg {
        z-index: 1;
        transform: translate(58px, -180px);
        border-radius: 30px;
        background-size: 100% 100%;
       
      }
      .signimg3{
        width: 754px;
        height: 511px;
      }
      .contenttitle {
        width: 600px;
        // height: 40px;
        font-size: 36px;
        font-family: PingFang SC Bold, PingFang SC Bold-Bold;
        font-weight: 700;
        text-align: left;
        // color: #ff712a;
        color:#2FA7E5;
        // line-height: 40px;
        margin: 0px 0px 20px 0px;
      }
      .sort {
        width: 71px;
        height: 2px;
        // border: 1px solid #ff712a;
        border: 1px solid #2FA7E5;
      }
      .phonebox {
        width: 420px;
        .title_phone {
          width: 42px;
          height: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC Regular,
            Source Han Sans SC Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #a8a8a8;
          line-height: 20px;
          margin: 30px 0px 10px 0px;
        }
      }
      .title_phone {
        width: 42px;
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans SC Regular,
          Source Han Sans SC Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #a8a8a8;
        line-height: 20px;
        margin: 30px 0px 10px 0px;
      }
      .dis {
        display: flex;
        width: 420px;
        .verification {
          width: 170px;
          height: 40px;
          font-size: 16px;
          font-family: Source Han Sans SC Regular,
            Source Han Sans SC Regular-Regular;
          font-weight: 400;
          text-align: center;
          // color: #ff712a;
          color: #2179FA;
          line-height: 40px;
          border-bottom: 1px solid #dcdfe6;
        }
      }
      .read {
        display: flex;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        span {
          display: flex;
          margin-left: 10px;
          .user{
            color: #43a8fe;
            font-family: Source Han Sans SC Regular,
              Source Han Sans SC Regular-Regular;
            font-weight: 400;
          }
          .useragreement {
            color: #43a8fe;
            font-family: Source Han Sans SC Regular,
              Source Han Sans SC Regular-Regular;
            font-weight: 400;
          }
          .privacypolicy {
            color: #43a8fe;
            font-family: Source Han Sans SC Regular,
              Source Han Sans SC Regular-Regular;
            font-weight: 400;
          }
        }
      }
      .signbox {
        // width: 421px;
        // height: 46px;
        // background: linear-gradient(#ff712a 0%, #fd6e08 100%);
        // border-radius: 6px;

        width: 421px;
        height: 46px;
        background: linear-gradient(#2f81ed 0%, #2ee9d8 100%);
        border-radius: 32px;
        box-shadow: 1.53px 7.85px 9px 0px rgba(138,138,138,0.12); 

        div {
          width: 421px;
          height: 46px;
          font-size: 16px;
          font-family: PingFang SC Bold, PingFang SC Bold-Bold;
          font-weight: 700;
          text-align: center;
          color: #ffffff;
          line-height: 46px;
        }
      }
      .signbox2 {
        // width: 421px;
        // height: 46px;
        // background: linear-gradient(#ff712a 0%, #fd6e08 100%);
        // border-radius: 6px;

        width: 421px;
        height: 46px;
        background: linear-gradient(#2078fa 0%, #59c1fd 100%);
        border-radius: 34px; 

        div {
          width: 421px;
          height: 46px;
          font-size: 16px;
          font-family: PingFang SC Bold, PingFang SC Bold-Bold;
          font-weight: 700;
          text-align: center;
          color: #ffffff;
          line-height: 46px;
        }
      }
      el-input {
        color: #484545;
      }
    }
  }

.mar_10 {
  margin: 10px;
}
/deep/.el-input__inner {
  border: 1px transparent;
  border-radius: 0px;
  border-bottom: 1px solid #dcdfe6;
}
::v-deep input::-webkit-input-placeholder {
  color: #000;
}
</style>
